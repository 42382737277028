<template>
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="submitBillingSheet" class="mt-10" v-if="!terms">
      <div class="mb-5">
        <label class="block mb-2">Tag Number</label>
        <input
          type="text"
          class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
          v-model="corpse.corpse_tag"
          disabled
        />
      </div>
      <div class="mb-5">
        <label class="block mb-2">Name of Deceased</label>
        <input
          type="text"
          class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
          v-model="corpse.name_of_deceased"
          disabled
        />
      </div>
      <h2 class="text-sm font-bold text-gray-700 my-4">
        Please find below the cost for various mortuary services:
      </h2>
      <div class="mb-5">
        <label class="block mb-2">Embalmment Fee ₦</label>
        <ValidationProvider
          name="Embalment fee"
          rules="required|numeric"
          v-slot="{ errors }"
        >
          <input
            type="number"
            placeholder="NGN"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
            v-model="billing.embalment_fee"
          />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2"
          >Storage Fees ₦ (per day within the first 30 days)</label
        >
        <ValidationProvider
          name="Storage Fees"
          rules="required|numeric"
          v-slot="{ errors }"
        >
          <input
            type="number"
            placeholder="NGN"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
            v-model="billing.storage_fee"
          />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <h2 class="text-sm text-gray-700 my-4">
        N.B. Each subsequent month (30 days), there is a ₦100.00 increase on
        daily storage fees.<br /><br />
        N.B. Please also note that charges begin from date of admission to date
        of discharge.
      </h2>
      <h2 class="text-sm font-bold text-gray-700 my-6">
        Mortuary service charge/handling fee: ₦5,000.00
      </h2>
      <div class="mb-0">
        <label class="block mb-2">I, (enter your name below)</label>
        <input
          type="text"
          class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
          v-model="name"
          disabled
        />
      </div>
      <h2 class="text-sm mb-5">
        the undersigned, have without duress, read and understood all of the
        <b class="cursor-pointer text-red-500" @click="terms = true">
          stated information </b
        >.
      </h2>
      <button
        class="w-full mt-10 bg-primary text-white py-3 rounded-md"
        :disabled="invalid || submitting"
      >
        Continue to invoice
      </button>
    </form>
    <div class="my-10" v-if="terms">
      <h2 class="mb-2 font-bold text-sm">
        ALWAYS PRODUCE THE ORIGINAL BILLING SHEET ON ATTENDANCE AT THE MORTUARY.
        AS PHOTOCOPIES ARE NOT ACCEPTED.
      </h2>
      <p class="text-sm mb-3">
        Once a deposit is paid, we expect the remaining embalmment fees to be
        balanced in full within 24 hours. If these fees are not balanced within
        this time, we may not be able to proceed with embalmment of the corpse.
        You are required to pay storage fees not longer than one month in
        arrears, otherwise the corpse would be considered unclaimed. Viewing of
        the corpse will only be allowed if a member of the family is in
        possession of the original billing sheet & receiot or pavments. Viewing
        is not allowed on the day prior to (before) the collection date. Please
        call to book for viewing and note that viewing time is between 10.00am
        and 1.00pm daily. Casket and clothing (including underwear) should be
        deposited at the mortuary before <b>2.00pm</b> on the day preceding
        collection date of the corpse.
      </p>
      <p class="text-sm">
        For hygiene reasons we DO NOT ACCEPT TOILETRIES. Corpses can only be
        released between the hours of 8am to 6pm on any day of the week.
        <b
          >RELATIONS ARE NOT ALLOWED IN THE MORTUARY DURING BATHING AND
          DRESSING.</b
        >
        On the day of collection, family should be in possession of the original
        billing sheet, receipts and also a photocopy of the death certificate.
      </p>
      <button
        class="w-full mt-5 text-white bg-gray-800 py-3 rounded-md"
        @click="terms = false"
      >
        Okay, Noted
      </button>
    </div>
  </ValidationObserver>
</template>

<script>
import { billingInvoice } from "@/services/addmission";

export default {
  name: "BillingSheet",
  props: {
    corpse: {
      required: true,
      type: Object,
    },
    name: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      billing: {
        embalment_fee: 0,
        storage_fee: 0,
        corpse_id: "",
      },
      submitting: false,
      terms: false,
    };
  },
  methods: {
    async submitBillingSheet() {
      this.submitting = true;
      this.billing.corpse_id = this.corpse.corpse_id;
      try {
        const response = await billingInvoice(this.billing);
        this.$emit("created", response.data.corpse);
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
      this.submitting = false;
    },
  },
};
</script>
